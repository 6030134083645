/* DOM elements must be ID */
// ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
var sampleOptions = {
    domElements: {
        days: 'days',
        hours: 'hours',
        minutes: 'minutes',
        seconds: 'seconds',
    },
    countDownDate: "Sep 19, 2020 15:37:25"
};



/* PLUGIN: COUNT DOWN TIME */
+(function () {

    var FstCountdownTime = /** @class */ (function () {

        /**
         * Constructor function
         */
        FstCountdownTime = function (options) {
            var countDown = this;

            countDown.setConstants();
            countDown.configure(options);
            countDown.elementsExistOnDom = countDown.filterExistElementOnDom();
            if (countDown.elementsExistOnDom.length) {
                countDown.start();
            }
        };


        /* Set countdown constants */
        FstCountdownTime.prototype.setConstants = function () {
            var countDown = this;

            countDown.CONSTANTS = {
                DAYS: 'days',
                HOURS: 'hours',
                MINUTES: 'minutes',
                SECONDS: 'seconds'
            }
        };


        /* Provide the default option */
        FstCountdownTime.prototype.getDefaultOptions = function () {
            var countDown = this;

            return {
                domElements: {
                    days: countDown.CONSTANTS.DAYS,
                    hours: countDown.CONSTANTS.HOURS,
                    minutes: countDown.CONSTANTS.MINUTES,
                    seconds: countDown.CONSTANTS.SECONDS,
                },
                countDownDate: 1645332593035
            };
        };


        /* Set the basic configuration */
        FstCountdownTime.prototype.configure = function (options) {
            var countDown = this,
                defaultOptions = countDown.getDefaultOptions();

            countDown.settings = countDown.deepExtend(defaultOptions, options);
        };


        /* Start calculate of time */
        FstCountdownTime.prototype.start = function () {
            var countDown = this,
                countDownDate = new Date(countDown.settings.countDownDate).getTime();

            intervalCBFn();
            countDown.countDownInterval = setInterval(intervalCBFn, 1000);

            function intervalCBFn() {
                var now = new Date().getTime(),
                    distance = countDownDate - now;

                    if(countDownDate <= now){
                        countDown.pause();
                        return;
                    }


                updatedValuesOnly = countDown.getUpdatedValuesOnly({
                    [countDown.CONSTANTS.DAYS]: Math.floor(distance / (1000 * 60 * 60 * 24)),
                    [countDown.CONSTANTS.HOURS]: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, "0"),
                    [countDown.CONSTANTS.MINUTES]: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0"),
                    [countDown.CONSTANTS.SECONDS]: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, "0"),
                });
                countDown.updateDOM(updatedValuesOnly);
            }
        };


        /* Update the time DOM */
        FstCountdownTime.prototype.updateDOM = function (time) {

            var countDown = this,
                i = 0,
                elementExistOnDOMKeys = Object.keys(countDown.elementsExistOnDom),
                elementExistOnDOMKeysLength = elementExistOnDOMKeys.length;

            for (i; i < elementExistOnDOMKeysLength; i++) {
                var key = elementExistOnDOMKeys[i],
                    isKeysExistOnTime = time.hasOwnProperty(key);

                if (isKeysExistOnTime && time[key])
                    countDown.elementsExistOnDom[key].innerHTML = time[key];

            };
        };


        /* Stop time countdown */
        FstCountdownTime.prototype.pause = function () {
            var countDown = this;
            clearInterval(countDown.countDownInterval);
        };


        /* Get the updated keys only */
        FstCountdownTime.prototype.getUpdatedValuesOnly = (function () {

            var oldTime = {};
            return function (time) {

                var updatedTime = {},
                    i = 0,
                    timeKeys = Object.keys(time),
                    timeKeysLength = timeKeys.length;

                for (i; i < timeKeysLength; i++) {
                    var key = timeKeys[i],
                        isKeyIsExistOnOldTime = oldTime.hasOwnProperty(key);
                    if (!isKeyIsExistOnOldTime || (isKeyIsExistOnOldTime && time[key] != oldTime[key]))
                        updatedTime[key] = time[key]
                }
                oldTime = time;
                return updatedTime;
            };

        })();


        /* Check is elements is exist on dom and return only those element only which are exist on dom */
        FstCountdownTime.prototype.filterExistElementOnDom = function () {

            var countDown = this,
                elementsExistOnDom = {
                    length: 0
                },
                selectors = countDown.settings.domElements,

                domElementsKeys = Object.keys(selectors),
                domElementsLength = domElementsKeys.length,
                i = 0;

            for (i; i < domElementsLength; i++) {
                var key = domElementsKeys[i],
                    selector = selectors[key],
                    $element = document.getElementById(selector);

                if ($element) {
                    elementsExistOnDom[key] = $element;
                    elementsExistOnDom.length += 1;
                } else
                    console.warn(selector + ' Id is not exist on DOM We will not able to update ' + selector + ' element. please check on plugin options');
            }
            return elementsExistOnDom;
        };


        /* Extend the object */
        FstCountdownTime.prototype.deepExtend = function (out) {
            out = out || {};

            for (var i = 1, len = arguments.length; i < len; ++i) {
                var obj = arguments[i];

                if (!obj) {
                    continue;
                }

                for (var key in obj) {
                    if (!obj.hasOwnProperty(key)) {
                        continue;
                    }
                    if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
                        out[key] = deepExtend(out[key], obj[key]);
                        continue;
                    }
                    out[key] = obj[key];
                }
            }

            return out;
        };

        return FstCountdownTime;

    }());


    window.FstCountdownTime = FstCountdownTime;

})();




/* Random background image */
+(function () {
    "use stick";

    var FstRandomBackground = /** @class */ (function () {
        FstRandomBackground = function (options) {
            var randomBackground = this;

            randomBackground.configure(options);

            randomBackground.$element = document.getElementById(randomBackground.settings.selector);
            if (randomBackground.$element) {
                setInterval(function () {
                    randomBackground.setNewBackgroundImage(randomBackground)
                }, randomBackground.settings.nextImageFetchInterval);
            }
        }

        /* Default configuration */
        FstRandomBackground.prototype.getDefaultConfig = function () {
            return {
                url: 'https://source.unsplash.com/random',
                selector: 'fst-dynamic-background',
                nextImageFetchInterval: 30000,
            }
        }

        /* Set the configuration */
        FstRandomBackground.prototype.configure = function (options) {
            var randomBackground = this,
                defaultOptions = randomBackground.getDefaultConfig();

            randomBackground.settings = randomBackground.deepExtend(defaultOptions, options);
        }

        /* Fetch the new background image */
        FstRandomBackground.prototype.fetchImage = function () {
            var randomBackground = this;
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', randomBackground.settings.url);
                xhr.send();
                xhr.onload = function () {
                    if (200 === xhr.status) {
                        resolve(xhr.responseURL);
                    }
                };
                xhr.onerror = function () {
                    console.log('%con ERROR IN FETCHING IMAGE', 'color: red;font-size:14px;')
                }
            })

        };

        /* Fetch new image and set as background image */
        FstRandomBackground.prototype.setNewBackgroundImage = function (that) {
            var randomBackground = that;
            randomBackground.fetchImage()
                .then(function (imgURL) {
                    var img = new Image();
                    img.src = imgURL;
                    img.onload = function () {
                        randomBackground.$element.style.background = "url(" + imgURL + ")";
                    }
                });
        };


        /* Extend the object */
        FstRandomBackground.prototype.deepExtend = function (out) {
            out = out || {};

            for (var i = 1, len = arguments.length; i < len; ++i) {
                var obj = arguments[i];

                if (!obj) {
                    continue;
                }

                for (var key in obj) {
                    if (!obj.hasOwnProperty(key)) {
                        continue;
                    }
                    if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
                        out[key] = deepExtend(out[key], obj[key]);
                        continue;
                    }
                    out[key] = obj[key];
                }
            }

            return out;
        };

        return FstRandomBackground;

    }());

    window.FstRandomBackground = FstRandomBackground;

})()


