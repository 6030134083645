/** 
 * FST FIX HEADER PLUGIN
 */
 +(function ($) {

    window.fst = window.fst || {};

    var FixHeader = /** @class  */ (function () {

        var _this;

        /** 
         *  Constructor function for inititilize the plugin 
         */
        function FixHeader(options) {
            _this = this;

            var defaultOptions = this.getDefaultSettings();
            this.settings = $.extend({}, defaultOptions, options);

            /* Extra options */
            this.settings.fixHeaderClass = 'fst-header-fix';

            var $selector = $(this.settings.selector);
            if( $selector.length && $selector.is(':visible')){
                this.settings.$selector = $selector;
                this.settings.headerOffset = this.getHeaderOffset();
                this.listenScroll();
                this.addWrapper();
            };
        };

        /** 
         * Add wrapper div in selector and set height
         * to fix jump bug
         * @returns {void}
         */
        FixHeader.prototype.addWrapper = function(){
            var selectorHeight= this.settings.$selector.outerHeight();
            this.settings.$selector.wrap('<div class="wrapper" style="height:'+selectorHeight+'px"></div>')
        };

        /** 
         * Provide the default settings
         * @returns {Object}
         */
        FixHeader.prototype.getDefaultSettings = function () {
            return {};
        };

        /** 
         * Provide the the header offset top value
         * @returns {Number}
         */
        FixHeader.prototype.getHeaderOffset = function () {
            return this.settings.$selector.offset().top;
        };

        /** 
         * Listen the window scroll
         * @returns {void}
         */
        FixHeader.prototype.listenScroll = function () {
            $(window).scroll( function(){
                var windowScrollValue = $(window).scrollTop();
                if( windowScrollValue > _this.settings.headerOffset ){
                    _this.settings.$selector.addClass(_this.settings.fixHeaderClass);
                }else{
                    _this.settings.$selector.removeClass(_this.settings.fixHeaderClass);
                }
            });
        };

        return FixHeader;

    }());

    window.fst.FixHeader = FixHeader;

})(jQuery)