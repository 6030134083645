/** 
 * Custom input number
 */

 +(function ($) {
  "use strict";

  var FstCustomInputNumber = /** @class  */ (function () {

      /** 
       * Constructor function for initilize the plugin
       */
      function FstCustomInputNumber(options) {
          var defaultOptions = this.getDefaultOptions();
          this.settings = $.extend({}, defaultOptions, options);
          this.$selector = $(this.settings.selector);

          if(this.$selector.length){
              this.addExtraElements();
          }
      };

     /** 
       * Provide the default options
       */
      FstCustomInputNumber.prototype.getDefaultOptions = function () {
          return {
              incrementButtonText: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>',
              decrementButtonText: '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/> </svg>',
          };
      };

      /** 
       * increment value by one
       */
      FstCustomInputNumber.prototype.incrementValue= function( $element, max ){
          var value = +$element.val();
          var newVlaue = max && value >= max ? value: value + 1;
          $element.val(newVlaue);
          $element.trigger('change');
      };

      /** 
       * decrement value by one
       */
      FstCustomInputNumber.prototype.decrementValue = function( $element, min ){
          var value = +$element.val();
          var newVlaue = min && value <= min ? value: value - 1;
          $element.val(newVlaue);
          $element.trigger('change');
      }

      /** 
       * Provide the increment and decrement buttons template 
       * and also bind the click event
       */
      FstCustomInputNumber.prototype.getNavButtons = function( $element, max, min ){
          var $incrementaButton = $('<button />').append(this.settings.incrementButtonText);
          var $decrementButton = $('<button />').append(this.settings.decrementButtonText);

          $incrementaButton.on('click', function(e){
              e.preventDefault();
              this.incrementValue($element, max);
          }.bind(this));

          $decrementButton.on('click', function(e){
              e.preventDefault();
              this.decrementValue($element, min)
          }.bind(this));

          return {
              $incrementaButton: $incrementaButton,
              $decrementButton: $decrementButton 
          }
      };

      /** 
       * Add extra elements
       */
       FstCustomInputNumber.prototype.addExtraElements = function(){
          this.$selector.each( function(i, element){
              var max = $(element).attr('max');
              var min = $(element).attr('min');
              var $element = $(element);

              var buttons = this.getNavButtons( $element, max, min);
              
              $element.wrap('<div class="fst-custom-input-wrapper"></div>');
              $element.after(buttons.$incrementaButton);
              $element.before(buttons.$decrementButton);
          }.bind(this));
       }


      return FstCustomInputNumber;
  }())

  window.FstCustomInputNumber = FstCustomInputNumber;

})(jQuery)