
/** ---------------------------------
 *  MasonryWrapper Plugin wrapper 
 *  --------------------------------- */

 +(function ($) {

    var FstMasonryWrapper = /** @class */ (function () {

        /* Custructor function */
        function FstMasonryWrapper(options) {
            var masonry = this;
            masonry.activeTab = null;
            masonry.tabSelector = null;
            masonry.contentSelector = null;
            masonry.loader = null;

            masonry.settings = Object.assign({}, masonry.getDefaultOptions(), options);
            if (masonry.isElementExist()) {
                masonry.hideContent();
                masonry.appendLoader();
                masonry.listenOnload();
                masonry.handleResize();
            }
        };

        /* Default configuration */
        FstMasonryWrapper.prototype.getDefaultOptions = function () {
            return {
                activeClass: 'active'
            };
        };


        /* Listen window load event and show content */
        FstMasonryWrapper.prototype.listenOnload = function () {
            var masonry = this;
            window.onload = function () {
                masonry.removeLoader();
                masonry.showContent();
                masonry.initialize();
                masonry.setActiveFirstChild();
            };
        }


        /* Hide tab content */
        FstMasonryWrapper.prototype.hideContent = function () {
            var masonry = this;
            masonry.contentSelector.style.display = 'none';
        };


        /* Show content */
        FstMasonryWrapper.prototype.showContent = function () {
            var masonry = this;
            masonry.contentSelector.style.display = 'block';
        };


        /* Check provided tab and content element exist on dom */
        FstMasonryWrapper.prototype.isElementExist = function () {
            var masonry = this;
            var tabSelector = document.querySelector(masonry.settings.tabSelector);
            var contentSelector = document.querySelector(masonry.settings.contentSelector);
            if (tabSelector && contentSelector) {
                masonry.tabSelector = tabSelector;
                masonry.contentSelector = contentSelector;
                return true;
            }
            return false;
        };


        /* Initialize the masonry */
        FstMasonryWrapper.prototype.initialize = function () {
            var masonry = this;
            masonry.instance = $(masonry.settings.contentSelector).isotope();
            masonry.createActiveIndicator();
            masonry.handleFilter();
        };

        /* update the position of active background and filter according to the active category */
        FstMasonryWrapper.prototype.setActiveFirstChild = function () {
            var masonry = this;
            var activeTab = $(masonry.settings.tabSelector).find('.'+masonry.settings.activeClass)[0];
            masonry.filterContent(activeTab);
        };

        /* Filter the content */
        FstMasonryWrapper.prototype.filterContent = function(ele){
            var masonry = this;
            masonry.activeTab = ele;
            $(ele).addClass(masonry.settings.activeClass).siblings().removeClass(masonry.settings.activeClass);
            var filterValue = ele.getAttribute('data-filter');
            masonry.updateActiveIndicatorProperties(ele);
            masonry.instance.isotope({
                filter: filterValue
            });
        }

        /* Handle masonry filter on click tab */
        FstMasonryWrapper.prototype.handleFilter = function () {
            var masonry = this;
            var tabs = document.querySelector(masonry.settings.tabSelector).children;
            var tabsLength = tabs.length;
            var i = 0;

            for (i; i < tabsLength; i++) {
                var tab = tabs[i];
                tab.addEventListener('click', function (e) {
                    e.preventDefault();
                    masonry.filterContent(e.target);
                })
            }
        };


        /* Create active indicator html with initial css properties */
        FstMasonryWrapper.prototype.createActiveIndicator = function () {
            var masonry = this;
            var tab = document.querySelector(masonry.settings.tabSelector);
            var firstTab = tab.firstElementChild;
            var firstTabWidth = firstTab.offsetWidth;
            var firstTabHeight = firstTab.offsetHeight;

            var $activeIndicator = document.createElement('DIV');
            $activeIndicator.classList.add("active-tab-indicator");
            $activeIndicator.style.height = firstTabHeight + "px";
            $activeIndicator.style.width = firstTabWidth + "px";
            $activeIndicator.style.left = 0 + "px";
            tab.append($activeIndicator);

            masonry.tab = tab;
            masonry.$activeIndicator = $activeIndicator;
        };


        /* update the position of active tab background color position */
        FstMasonryWrapper.prototype.handleResize = function () {
            var masonry = this;
            $(window).on('resize', function () {
                if (masonry.activeTab) {
                    masonry.updateActiveIndicatorProperties(masonry.activeTab);
                }
            })
        }

        /* Update the css properties of activeIndicator background */
        FstMasonryWrapper.prototype.updateActiveIndicatorProperties = function (element) {
            var masonry = this;
            var activeTabWidth = element.offsetWidth;
            var activeTabLeft = element.getClientRects()[0].x - masonry.tab.getClientRects()[0].x;
            var activeTabTop = element.getClientRects()[0].y - masonry.tab.getClientRects()[0].y;
            masonry.$activeIndicator.style.width = activeTabWidth + "px";
            masonry.$activeIndicator.style.left = activeTabLeft + "px";
            masonry.$activeIndicator.style.top = activeTabTop + "px";
        };


        /* Create loader template */
        FstMasonryWrapper.prototype.getLoaderTemplate = function () {
            var masonry = this;

            var loaderWrapper = document.createElement("DIV");
            loaderWrapper.setAttribute("class", "loader-wrapper min-height");

            var loaderIcon = document.createElement("SPAN");
            loaderIcon.setAttribute("class", "loader-icon");

            var loaderInner = document.createElement("SPAN");
            loaderInner.setAttribute("class", "loader-inner");

            var loaderText = document.createElement("DIV");
            loaderText.setAttribute("class", "loading-text")
            loaderText.innerHTML = "Loading...";

            loaderIcon.appendChild(loaderInner);
            loaderWrapper.appendChild(loaderIcon);
            loaderWrapper.appendChild(loaderText);

            masonry.loader = loaderWrapper;

            return loaderWrapper;
        };


        /* Append loader on dom */
        FstMasonryWrapper.prototype.appendLoader = function () {
            var masonry = this;
            var loaderTemplate = masonry.getLoaderTemplate();
            masonry.contentSelector && masonry.contentSelector.parentNode.append(loaderTemplate);
        };


        /* Remove loader */
        FstMasonryWrapper.prototype.removeLoader = function () {
            var masonry = this;
            masonry.loader && masonry.loader.remove()
        };

        return FstMasonryWrapper;

    }());


    window.FstMasonryWrapper = FstMasonryWrapper;

})(jQuery);