/** 
 * Scroll top plugin
 */

 +(function($){
    "use strict";

    var FstScrolltop = /** @class  */ (function(){

        /** 
         * Constructor function for initialize the plugin
         */
        function FstScrolltop(options){

            var defaultOptions = this.getDefaultOptions();
            this.settings = $.extend({}, defaultOptions, options);
            this.activeClassName = 'active';

            this.scrolltopButton = this.createStructure();
            this.scrolltopButtonAppendToDOM();
            this.listenScroll();
            this.handleScrolltopButtonClick();

        };

        /** 
         * Provide the default options
         */
        FstScrolltop.prototype.getDefaultOptions = function(){
            return {
                minScrollAmount: 30,
                animationDuration: 300,
                tooltipText: 'Scroll to top',
            };
        }

        /** 
         * Create the basic structure of scroll top button
         */
        FstScrolltop.prototype.createStructure = function(){
            var button = $('<button class="fst-scrolltop-button"></button>');
            var text = $('<span />').text(this.settings.tooltipText)
            var arrowUpIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/></svg>';
            button.append(arrowUpIcon).append(text);
            return button;
        };

        /** 
         * Scrolltop button append to Dom 
         */
        FstScrolltop.prototype.scrolltopButtonAppendToDOM = function( scrolltopButton ){
            $(document.body).append(this.scrolltopButton);
        };

        /** 
         * Listen the scroll event and take action accordingly
         */
        FstScrolltop.prototype.listenScroll = function(){
            var _this = this;
            $(window).on('scroll', function(){
                var scrollAmount = $(window).scrollTop();
                if(scrollAmount > this.settings.minScrollAmount){
                    this.showScrollTopButton();
                }else{
                    this.hideScrollTopButton();
                }
            }.bind(this) )
        };

        /** 
         * Handle the click on scroll top button
         */
        FstScrolltop.prototype.handleScrolltopButtonClick = function(){
            this.scrolltopButton.on('click', function(){
                this.scrollToTop();
            }.bind(this));
        }

        /** 
         * Show the scroll top button
         */
        FstScrolltop.prototype.showScrollTopButton = function(){
            this.scrolltopButton.addClass(this.activeClassName);
        };

        /** 
         * Hide the scroll top button
         */
        FstScrolltop.prototype.hideScrollTopButton = function(){
            this.scrolltopButton.removeClass(this.activeClassName);
        };

        /** 
         * Scroll document to top 0
         */
        FstScrolltop.prototype.scrollToTop = function(){
            var $selector = $('body, html');
            $selector.stop().animate({
                scrollTop: 0
              }, this.settings.animationDuration, "linear");
        };

        return FstScrolltop;
    }())

    window.FstScrolltop = FstScrolltop;

})(jQuery)