+(function ($) {

    window.fst = window.fst || {};

    var PriceTable = /* @class */ (function () {

        var _this, $activeIndicator;

        /** 
         * Contructor function
         */
        function PriceTable(options) {
            _this = this;
            var defaultSettings = this.getDefaultOptions();
            this.settings = $.extend({}, defaultSettings, options);

            /* If required elelements are not found in DOM then return from here */
            if (!this.isAllElementExist()) return false;

            this.handlePlanSwitcher();
            this.createActiveIndicator();
            this.setDefaultPackageValue();
        };

        /** 
         * Provide the default settings of price table
         * @returns {void}
         */
        PriceTable.prototype.getDefaultOptions = function () {
            return {
                switcherSelector: '.name',
                planSelector: '.fst-plan',
                packages: [],
            }
        };

        /** 
         * Check all dom selector element exist in DOM
         * @returns {Boolean}
         */
        PriceTable.prototype.isAllElementExist = function () {
            var $switcherSelector = $(this.settings.switcherSelector);
            var $planSelector = $(this.settings.planSelector);

            if (!$switcherSelector.length || !$planSelector.length) return false;

            /* Store the selector */
            this.settings.$switcherSelector = $switcherSelector;

            $planSelector.each(function (i, $element) {
                const dataAttr = $($element).data();
                const $selector = $(dataAttr.target);
                if ($selector.length) {
                    const packageData = $.extend({}, dataAttr, { $selector: $selector });
                    _this.settings.packages.push(packageData)
                }
            });

            return true;
        };

        /** 
         * Set the default package values
         * @returns {void}
         */
        PriceTable.prototype.setDefaultPackageValue = function () {
            var $selectedPlanParent = _this.settings.$switcherSelector.parent();
            var value = $('input:checked', $selectedPlanParent).val();
            _this.updatePlanPrice(value);
        }

        /** 
         * This function bind the event for plan switcher
         * @return {void}
         */
        PriceTable.prototype.handlePlanSwitcher = function () {
            $(this.settings.$switcherSelector).on('click', function () {
                var value = $(this).val();
                _this.updateIndicatorProperty();
                _this.updatePlanPrice(value);
            })
        };

        /** 
         * Update the plan price according subscription plan
         * @param {String} subscription -Subscription duration name ["month", "year"]
         * @return {void}
         */
        PriceTable.prototype.updatePlanPrice = function (subscription) {
            $.each(_this.settings.packages, function (index, value) {
                value.$selector.text(value[subscription])
            })
        };

        /** 
         * Update the css properties of activeIndicator background 
         * @returns {void}
         */
        PriceTable.prototype.updateIndicatorProperty = function () {
            var $selectedPlanParent = _this.settings.$switcherSelector.parent();
            var $selectedPlan = $('input:checked + label', $selectedPlanParent);
            var selectedPlanWidth = $selectedPlan.outerWidth() - 1;
            $activeIndicator.style.width = selectedPlanWidth + "px";
            $activeIndicator.style.left = $selectedPlan.offset().left - $selectedPlanParent.offset().left + "px";
        };

        /**
         *  Create active indicator html with initial css properties 
         * @returns {void}
         */
        PriceTable.prototype.createActiveIndicator = function () {

            var $selectedPlanParent = this.settings.$switcherSelector.parent();
            var $selectedPlan = $('input:checked + label', $selectedPlanParent);
            var selectedPlanWidth = $selectedPlan.outerWidth() - 1;
            var selectedPlanHeight = $selectedPlan.outerHeight();

  
            $activeIndicator = document.createElement('DIV');
            $($activeIndicator).addClass("fst-indicator");
            $activeIndicator.style.height = selectedPlanHeight + "px";
            $activeIndicator.style.width = selectedPlanWidth + "px";
            $activeIndicator.style.left = $selectedPlan.offset().left - $selectedPlanParent.offset().left + "px";
            $selectedPlanParent.append($activeIndicator);
        };

        return PriceTable;
    }());

    window.fst.PriceTable = PriceTable;

})(jQuery)